@import "../main/abstracts/index";
body .off-canvas__toggle--open{
    background-color: transparent;
    span.lines{
        background-color: $color__primary;
        margin-left: 10px;
        width: 28px;
        @include transition;
        &:before,
        &::after{
            background-color: $color__primary;
            width: 38px;
            left: unset;
            right: 0;
            @include transition
        }
    }
    span.text{
        color: $color__font;
    }
   
    @include hover-focus-visible{
        background-color: transparent;
        span.lines{
            margin-left: 0px;
            width: calc(38px);
            &:after,
            &:before{
                width: 38px;
            }
        }
    }
}
body .meta-navigation--crumb{
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($color__gray-list,0.25);
    ol {
        li{
            a{
                font-weight: $font__weight--normal;
                
            }
        }
    }
}
body .meta-navigation--crumb .meta-navigation__item:not(:last-child)::after{
    background-image: url('/assets/website/img/arrow.svg');
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-left: 5px;
}
.headroom--unpinned{
    .off-canvas__toggle--open span.text{
        display: none;
    }
}
html.js body .main-navigation__header.main-navigation__header--home>a::before{
    @include recolor($color__primary)
}
.off-canvas__inner .main-navigation__item{
    a{
        &:before{
            display: none; 
        }
    }
}
html.js .main-navigation__header.main-navigation__header--home>a::before{
    position: relative;
}
html.js body .main-navigation__item.main-navigation__item--active>a>span[aria-hidden], html.js body .main-navigation__item.main-navigation__item--active>button>span[aria-hidden], html.js body .main-navigation__item.main-navigation__item--active>button>a>span[aria-hidden]{
    @include recolor($color__font);
} 
html.js body .main-navigation__item.main-navigation__item--home>a::before{
    position: relative;
    @include recolor($color__font)
}

html.js body.navigation-open{
    .off-canvas__toggle--close{
        @include media-breakpoint-up(md) {
            border-radius: 40px;
            width: 60px;
            height: 60px;
            right: 15px;
            top: 15px;
        }
        @include media-breakpoint-down(md) {
            border-radius: 8px;
            width: 70px;
            height: 70px;
            top: 5px;
            right: 5px
        }
        
    }

}
html.js .main-navigation__link>span:not([aria-hidden]){
    background-image: none;
    font-weight: 400;

}
html.js .main-navigation__item.main-navigation__item--home>a{
    color: $color__font;   
}
html.js body .main-navigation__button.main-navigation__button--prev{
    text-decoration: none;
    color: $color__font;
    font-weight: $font__weight--normal;
    padding: 0

}
html.js body .main-navigation__button{
    font-weight: $font__weight--normal;
}
html.js body .main-navigation__link{
    padding: 0 35px;
}
html.js body .main-navigation__item.main-navigation__item--back{
    .main-navigation__button.main-navigation__button--prev{
        position: relative;

        background-color: rgba(0, 0, 0, 0);
        background-image: linear-gradient(to right, #CB0037 50%, transparent 50%),linear-gradient(to bottom, transparent calc(100% - 0.0625rem), #8F98AB calc(100% - 0.0625rem));
                background-position: 100% 100%,0 0;
                background-repeat: no-repeat;
                background-size: 200% 0.1875rem,100% 100%;

        padding-bottom: 4px;

        @include transition();

        &:hover,
        &:focus-visible {
            background-position: 0 100%, 0 0;
        }
    }
}
html.js body .main-navigation__item .main-navigation__button{
    padding: 0 35px;
    &:has(.main-navigation__link){
        padding: 0;
    }
}
html.js body .main-navigation__item.main-navigation__item--header{
    padding: 1.5rem 35px;
    span.h3{
        margin: 0;
    }
}
html.js body .main-navigation__item.main-navigation__item--home>a{
    color: $color__font;
    font-weight: $font__weight--normal;
    text-decoration: none;
}
html.js body .main-navigation__button--prev>span:not([aria-hidden]){
    padding-left: 15px;
}

html.js body .main-navigation__button.main-navigation__button--prev>span[aria-hidden]{
    background-image: url('/assets/website/img/arrow.svg');
    @include recolor($color__primary);
    transform: rotate(180deg);
}
html.js body .main-navigation__item .main-navigation__button>span[aria-hidden]{
    background-image: url('/assets/website/img/arrow.svg');
    @include recolor($color__primary);
}
html.js body .off-canvas__inner{
    padding-top: 80px;
}